import React from 'react';
import '../assets/css/index.css';

import {imgInfo} from "./common";

export default class LoadingComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = { loading:props.loading, waitClass:''};
	}

	componentDidMount() {
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.state.loading !== nextProps.loading) {
			this.setState({loading:nextProps.loading});
		}
	}

	clickWaitPan = () => {
		this.setState({waitClass:'active'});
		setTimeout(() => {
			this.props.clickWaitPan();
		}, 1000);
	}

	render() {
		const {loading, waitClass} = this.state;
		return (
			<React.Fragment>
				{loading === 'loading' &&
					<div className='page-wrapper loading-wrapper'>
						<img src={imgInfo.load}></img>
						<label>LOADING</label>
					</div>
				}
				{loading === 'waiting' &&
					<div className={`page-wrapper loading-wrapper waiting-wrapper ${waitClass}`} onClick={this.clickWaitPan}>
						<div className='icon'><img src={imgInfo.fist}></img></div>
						<label>DRAG TO ROTATE</label>
						<div className='description'>TAP TO CONTINUE</div>
					</div>
				}
			</React.Fragment>
		);
	}
}
