import React from 'react'
import Proptypes from 'prop-types'
import { Route as ReactDOMRoute } from 'react-router-dom'

const Route = ({ component: Component, ...rest }) => {
  return <ReactDOMRoute {...rest} render={() => <Component />} />
}

Route.propTypes = {
  component: Proptypes.func,
}

export default Route
