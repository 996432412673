import React from 'react';
import '../assets/css/index.css';

import {imgInfo} from "./common";

export default class HotModalComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = { selModel:props.selModel, hotInfo:props.hotInfo, hotClass:props.hotClass, other2530:props.other2530, other4045:props.other4045};
	}

	componentDidMount() {
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.state.hotInfo !== nextProps.hotInfo) {
			this.setState({hotInfo:nextProps.hotInfo});
		}
		if (this.state.hotClass !== nextProps.hotClass) {
			this.setState({hotClass:nextProps.hotClass});
		}
		if (this.state.selModel !== nextProps.selModel) {
			this.setState({selModel:nextProps.selModel});
		}
		if (this.state.other2530 !== nextProps.other2530) {
			this.setState({other2530:nextProps.other2530});
		}
		if (this.state.other4045 !== nextProps.other4045) {
			this.setState({other4045:nextProps.other4045});
		}
	}

	render() {
		const {selModel, hotInfo, hotClass, other2530, other4045} = this.state;
		var hotTitle = hotInfo.title;
		if 		(selModel === 'model_2530' && other2530 === true) hotTitle = hotInfo.otherTitle;
		else if (selModel === 'model_4045' && other4045 === true) hotTitle = hotInfo.otherTitle;
		return (
			<div className={`page-back hot-modal ${hotClass}`}>
				<div className='wrapper'>
					<div className='title'>{hotTitle}</div>
					<div className='des'>{hotInfo.type} Thickness : {hotInfo.thick} mm</div>
				</div>
				<img className='close-button' src={imgInfo.close} onClick={this.props.closeHotModal}></img>
			</div>
		);
	}
}
