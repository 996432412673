import * as THREE  from 'three';
import imgLoad from "../assets/images/load.gif";
import imgLogo from "../assets/images/logo.svg";
import imgSplit from "../assets/images/split.png";
import imgHotspot from "../assets/images/hotspot.png";
import imgHotspotHover from "../assets/images/hotspotHover.png";
import imgClose from "../assets/images/close-button.png";
import imgFist from "../assets/images/drag-icon.png";
import imgPen from "../assets/images/pen.svg";

import model2027 from '../assets/models/8-2027/8-2027.fbx'; import img2027 from '../assets/models/8-2027/8-2027.png'; import img2027Top from '../assets/models/8-2027/8-2027-top.jpg'; import img2027Bottom from '../assets/models/8-2027/8-2027-bottom.png';
import model2530 from '../assets/models/8-2530/8-2530.fbx'; import img2530 from '../assets/models/8-2530/8-2530.png'; import img2530Top from '../assets/models/8-2530/8-2530-top.png';
import model2450 from '../assets/models/8-2450/8-2450.fbx'; import img2450 from '../assets/models/8-2450/8-2450.png'; import img2450Top from '../assets/models/8-2450/8-2450-top.jpg'; import img2450Bottom from '../assets/models/8-2450/8-2450-bottom.jpg';
import model2911 from '../assets/models/8-2911/8-2911.fbx'; import img2911 from '../assets/models/8-2911/8-2911.png'; import img2911Top from '../assets/models/8-2911/8-2911-top.jpg'; import img2911Bottom from '../assets/models/8-2911/8-2911-bottom.png';
import model3092 from '../assets/models/8-3092/8-3092.fbx'; import img3092 from '../assets/models/8-3092/8-3092.png'; import img3092Top from '../assets/models/8-3092/8-3092-top.png'; import img3092Bottom from '../assets/models/8-3092/8-3092-bottom.jpg';
import model3807 from '../assets/models/8-3807/8-3807.fbx'; import img3807 from '../assets/models/8-3807/8-3807.png'; import img3807Top from '../assets/models/8-3807/8-3807-top.png'; import img3807Bottom from '../assets/models/8-3807/8-3807-bottom.png'; import img3807Middle from '../assets/models/8-3807/8-3807-middle.png';
import model3824 from '../assets/models/8-3824/8-3824.fbx'; import img3824 from '../assets/models/8-3824/8-3824.png'; import img3824Top from '../assets/models/8-3824/8-3824-top.png'; import img3824Bottom from '../assets/models/8-3824/8-3824-bottom.png';
import model3859 from '../assets/models/8-3859/8-3859.fbx'; import img3859 from '../assets/models/8-3859/8-3859.png'; import img3859Top from '../assets/models/8-3859/8-3859-top.png'; import img3859Bottom from '../assets/models/8-3859/8-3859-bottom.png'; import img3859Middle from '../assets/models/8-3859/8-3859-middle.png';
import model3879 from '../assets/models/8-3879/8-3879.fbx'; import img3879 from '../assets/models/8-3879/8-3879.png'; import img3879Top from '../assets/models/8-3879/8-3879-top.png'; import img3879Bottom from '../assets/models/8-3879/8-3879-bottom.png';
import model4045 from '../assets/models/8-4045/8-4045.fbx'; import img4045 from '../assets/models/8-4045/8-4045.png'; import img4045Top from '../assets/models/8-4045/8-4045-top.png'; import img4045Bottom from '../assets/models/8-4045/8-4045-bottom.png'; import img4045Middle from '../assets/models/8-4045/8-4045-middle.png';
import model4057 from '../assets/models/8-4057/8-4057.fbx'; import img4057 from '../assets/models/8-4057/8-4057.png'; import img4057Top from '../assets/models/8-4057/8-4057-top.png'; import img4057Bottom from '../assets/models/8-4057/8-4057-bottom.png';
import model4104 from '../assets/models/8-4104/8-4104.fbx'; import img4104 from '../assets/models/8-4104/8-4104.png'; import img4104Top from '../assets/models/8-4104/8-4104-top.png'; import img4104Bottom from '../assets/models/8-4104/8-4104-bottom.png';
import model4111 from '../assets/models/8-4111/8-4111.fbx'; import img4111 from '../assets/models/8-4111/8-4111.png'; import img4111Top from '../assets/models/8-4111/8-4111-top.png'; import img4111Bottom from '../assets/models/8-4111/8-4111-bottom.png';
import model4142 from '../assets/models/8-4142/8-4142.fbx'; import img4142 from '../assets/models/8-4142/8-4142.png'; import img4142Top from '../assets/models/8-4142/8-4142-top.png'; import img4142Bottom from '../assets/models/8-4142/8-4142-bottom.png'; import img4142Middle from '../assets/models/8-4142/8-4142-middle.png';
import model4155 from '../assets/models/8-4155/8-4155.fbx'; import img4155 from '../assets/models/8-4155/8-4155.png'; import img4155Top from '../assets/models/8-4155/8-4155-top.png'; import img4155Bottom from '../assets/models/8-4155/8-4155-bottom.png'; import img4155Middle from '../assets/models/8-4155/8-4155-middle.png';
import model4159 from '../assets/models/8-4159/8-4159.fbx'; import img4159 from '../assets/models/8-4159/8-4159.png'; import img4159Top from '../assets/models/8-4159/8-4159-top.png'; import img4159Bottom from '../assets/models/8-4159/8-4159-bottom.png'; import img4159Middle from '../assets/models/8-4159/8-4159-middle.png';

export const colorArr = [{val: 0x1F1E1D, str:'#464442'}, {val: 0x62A069, str:'#A6E4AD'}]; // 84C28B

// const device =  ( /Android|webOS|iPhone|iPad|Macintosh|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) ? 'mobile':'web';
// const matPosZ = 2.6 * posRatio, hotPosYBottom = 0.2, hotPosYTop=1.2 * posRatio, hotPosX = 1.5;
// const hotTopPos = {x:hotPosX, y:hotPosYTop, z:matPosZ}, hotBottomPos = {x:-hotPosX, y:hotPosYBottom, z:matPosZ};

export const imgArr = [img2027Top, img2027Bottom, img2530Top, img2450Top, img2450Bottom, img2911Top, img2911Bottom, img3092Top, img3092Bottom, img3807Top, img3807Middle, img3807Bottom, img3824Top, img3824Bottom, img3859Top, img3859Middle, img3859Bottom, img3879Top, img3879Bottom, img4045Top, img4045Bottom, img4057Top, img4057Bottom, img4104Top, img4104Bottom, img4111Top, img4111Bottom, img4142Top, img4142Middle, img4142Bottom, img4155Top, img4155Middle, img4155Bottom, img4159Top, img4159Middle, img4159Bottom];

export const modelInfo = [
	{ label: 'REF. 2027', id:'model_2027', file: model2027,
		hotInfo:[
			{name:'Top', type:'Top', title:'VISCOFLEX 5016 BRANCO (O2)', thick:'50', splitPos:1, img:img2027Top, imgNum:0, deltaY:1},
			{name:'Bottom', type:'Bottom', title:'ETER 3039 ROSA (M6)', thick:'200', splitPos:-1, img:img2027Bottom, imgNum:1}
		], img:img2027
	},
	{ label: 'REF. 2530', id:'model_2530', file: model2530,
		hotInfo:[
			{name:'Top', type:'Top', title:'ETER 2835 BEGE (F9)', thick:'43', splitPos:1, img:img2530Top, imgNum:2},
			{name:'Bottom', type:'Bottom', title:'ETER 3051 CINZA (M0)', thick:'90', splitPos:-1, col:colorArr[0].val, otherTitle:'ETER 3051 VERDE (M0)'}
		], img:img2530, colorInfo:{Bottom:[{num:0, set:true}, {num:1, set:false}]}
	},
	{ label: 'REF. 2450', id:'model_2450', file: model2450,
		hotInfo:[
			{name:'Top', type:'Top', title:'ETER 3223 BEGE (B3)', thick:'110', splitPos:1, img:img2450Top, imgNum:3},
			{name:'Bottom', type:'Bottom', title:'BIOCELL 3230 VERDE (P5)', thick:'110', splitPos:-1, img:img2450Bottom, imgNum:4}
		], img:img2450
	},
	{ label: 'REF. 2911', id:'model_2911', file: model2911,
		hotInfo:[
			{name:'Top', type:'Top', title:'BIOCELL 3230 VERDE (P5)', thick:'30', splitPos:1, img:img2911Bottom, imgNum:6, deltaY:0.5},
			{name:'Middle', type:'Middle', disable:true, splitPos:0, img:img2911Top, imgNum:5},
			{name:'Bottom', type:'Bottom', title:'ETER 3330 BRANCO (F7)', thick:'40', splitPos:-1, img:img2911Bottom, imgNum:6},
		], img:img2911
	},
	{ label: 'REF. 3092', id:'model_3092', file: model3092,
		hotInfo:[
			{name:'Top', type:'Top', title:'VISCOFLEX 8517 AZUL (O3)', thick:'55', splitPos:1, img:img3092Top, imgNum:7, deltaY:1.1},
			{name:'Bottom', type:'Bottom', title:'ETER 3330 BRANCO (F7)', thick:'150', splitPos:-1, img:img3092Bottom, imgNum:8},
		], img:img3092
	},
	{ label: 'REF. 3807', id:'model_3807', file: model3807,
		hotInfo:[
			{name:'Top', type:'Top', title:'VISCOFLEX 4216 BRANCO (DE)', thick:'60', splitPos:1, img:img3807Top, imgNum:9, deltaY:1.1},
			{name:'Middle', type:'Middle', title:'ETER 3027 BEGE (P3)', thick:'60', splitPos:0, img:img3807Middle, imgNum:10},
			{name:'Bottom', type:'Bottom', title:'ETER 2836 VERDE (K0)', thick:'100', splitPos:-1, img:img3807Bottom, imgNum:11},
		], img:img3807
	},
	{ label: 'REF. 3824', id:'model_3824', file: model3824,
		hotInfo:[
			{name:'Top', type:'Top', title:'VISCOFLEX 4216 BRANCO (DE)', thick:'30', splitPos:1, img:img3824Top, imgNum:12},
			{name:'Bottom', type:'Bottom', title:'ETER 2534 BRANCO (H2)', thick:'120', splitPos:-1, img:img3824Bottom, imgNum:13},
		], img:img3824
	},
	{ label: 'REF. 3859', id:'model_3859', file: model3859,
		hotInfo:[
			{name:'Top', type:'Top', title:'ETER 3223 BEGE (B3)', thick:'30', splitPos:1, img:img3859Top, imgNum:14, deltaY:1.1},
			{name:'Middle', type:'Middle', title:'BIOVISCO 5016 VERDE (Q9)', thick:'40', splitPos:0, img:img3859Middle, imgNum:15, deltaY:0.8},
			{name:'Bottom', type:'Bottom', title:'ETER 2832 CINZA (DG)', thick:'130', splitPos:-1, img:img3859Bottom, imgNum:16},
		], img:img3859
	},
	{ label: 'REF. 3879', id:'model_3879', file: model3879,
		hotInfo:[
			{name:'Top', type:'Top', title:'BIOVISCO 5016 VERDE (Q9)', thick:'60', splitPos:1, img:img3879Top, imgNum:17, deltaY:1},
			{name:'Bottom', type:'Bottom', title:'ETER 2629 BEGE (Q4)', thick:'150', splitPos:-1, img:img3879Bottom, imgNum:18},
		], img:img3879
	},
	{ label: 'REF. 4045', id:'model_4045', file: model4045,
		hotInfo:[
			{name:'Top', type:'Top', title:'VISCOFLEX 4216 BRANCO (DE)', thick:'80', splitPos:1, img:img4045Top, imgNum:19, deltaY:1},
			{name:'Middle', type:'Middle', title:'ETER 3051 VERDE (M2)', thick:'75', splitPos:0, col:colorArr[1].val, otherTitle:'ETER 3051 CASTANHO (M2)'}, // , img:img4045Middle
			{name:'Bottom', type:'Bottom', title:'ETER 3035 SALMÃO (N2)', thick:'80', splitPos:-1, img:img4045Bottom, imgNum:20},
		], img:img4045, colorInfo:{Middle:[{num:1, set:true}, {num:0, set:false}]}
	},
	{ label: 'REF. 4057', id:'model_4057', file: model4057,
		hotInfo:[
			{name:'Top', type:'Top', title:'VISCOFLEX 5016 BRANCO (O2)', thick:'20', splitPos:1, img:img4057Top, imgNum:21},
			{name:'Bottom', type:'Bottom', title:'ETER 2629 SALMÃO (Q4)', thick:'140', splitPos:-1, img:img4057Bottom, imgNum:22},
		], img:img4057
	},
	{ label: 'REF. 4104', id:'model_4104', file: model4104,
		hotInfo:[
			{name:'Top', type:'Top', title:'ETER 3820 AZUL (JJ)', thick:'40', splitPos:1, img:img4104Top, imgNum:23, deltaY:1.3},
			{name:'Middle', type:'Middle', title:'VISCOFLEX 5021 BRANCO (O7)', thick:'40', splitPos:0, img:img4104Bottom, imgNum:24, deltaY:1},
			{name:'Bottom', type:'Bottom', title:'ETER 2534 BRANCO (H1)', thick:'160', splitPos:-1, img:img4104Bottom, imgNum:24},
		], img:img4104
	},
	{ label: 'REF. 4111', id:'model_4111', file: model4111,
		hotInfo:[
			{name:'Top', type:'Top', title:'VISCOFLEX 5016 BRANCO (O2)', thick:'20', splitPos:1, img:img4111Top, imgNum:25, deltaY:1.1},
			{name:'Bottom', type:'Bottom', title:'ETER 2629 SALMÃO (Q4)', thick:'140', splitPos:-1, img:img4111Bottom, imgNum:26},
		], img:img4111
	},
	{ label: 'REF. 4142', id:'model_4142', file: model4142,
		hotInfo:[
			{name:'Top', type:'Top', title:'ETER 3820 AZUL (JJ)', thick:'40', splitPos:1, img:img4142Top, imgNum:27, deltaY:1.3},
			{name:'Middle', type:'Middle', title:'VISCOFLEX 5021 BRANCO (O7)', thick:'40', splitPos:0, img:img4142Middle, imgNum:28, deltaY:1},
			{name:'Bottom', type:'Bottom', title:'ETER 2534 AZUL (H1)', thick:'160', splitPos:-1, img:img4142Bottom, imgNum:29},
		], img:img4142
	},
	{ label: 'REF. 4155', id:'model_4155', file: model4155,
		hotInfo:[
			{name:'Top', type:'Top', title:'ETER 3027 BEGE (P3)', thick:'40', splitPos:1, img:img4155Top, imgNum:30, deltaY:1.3},
			{name:'Middle', type:'Middle', title:'VISCOFLEX 5021 BRANCO (O7)', thick:'39', splitPos:0, img:img4155Middle, imgNum:31, deltaY:1},
			{name:'Bottom', type:'Bottom', title:'ETER 2835 BEGE (F9)', thick:'170', splitPos:-1, img:img4155Bottom, imgNum:32},
		], img:img4155
	},
	{ label: 'REF. 4159', id:'model_4159', file: model4159,
		hotInfo:[
			{name:'Top', type:'Top', title:'ETER 3223 BEGE (B3)', thick:'40', splitPos:1, img:img4159Top, imgNum:33, deltaY:1.3},
			{name:'Middle', type:'Middle', title:'VISCOFLEX 5016 BRANCO (O2)', thick:'40', splitPos:0, img:img4159Middle, imgNum:34, deltaY:1},
			{name:'Bottom', type:'Bottom', title:'ETER 2835 BEGE (F9)', thick:'170', splitPos:-1, img:img4159Bottom, imgNum:35},
		], img:img4159
	},
];

export const imgInfo = {
	load:imgLoad, logo:imgLogo, split:imgSplit, close:imgClose, fist:imgFist, hotHover:imgHotspotHover, pen:imgPen
}

export function Get2DPos(obj, cWidth, cHeight, camera) {
	var vector = new THREE.Vector3();
	var widthHalf = 0.5 * cWidth;
	var heightHalf = 0.5 * cHeight;
	obj.updateMatrixWorld();
	vector.setFromMatrixPosition(obj.matrixWorld);
	vector.project(camera);
	vector.x = ( vector.x * widthHalf ) + widthHalf;
	vector.y = - ( vector.y * heightHalf ) + heightHalf;
	return { x: vector.x, y: vector.y };
};

export function RemoveChild(object, removeInfo) {
	if (!removeInfo) return object;
	object.children.forEach((child, idx) => {
		removeInfo.forEach(removeIdx => {
			if (removeIdx === idx)	child.removeChild = true;
		});
	});
	for (let i = object.children.length - 1; i >= 0; i--) {
		if (object.children[i].removeChild) object.remove(object.children[i]);
	}
	return object;
}

export function GetHotMesh(type, deltaY) { // scl, 
	const hotMap = new THREE.TextureLoader().load(imgHotspot);
	const hotGeo = new THREE.PlaneGeometry(0.9, 0.9);
	const hotMat = new THREE.MeshStandardMaterial({depthTest:false, map:hotMap, transparent:true, side:2});
	const hotMesh = new THREE.Mesh(hotGeo, hotMat);
	hotMesh.hotType = type; hotMesh.deltaY = deltaY;
	// hotMesh.scale.set(scl, scl, scl);
	// hotMesh.oriScl = scl;
	// var hotPos = {x:0, y:hotPosYMiddle, z:matPosZ};
	// if 		(type === 'Top') hotPos = hotTopPos;
	// else if (type === 'Bottom') hotPos = hotBottomPos;
	// hotMesh.position.set(hotPos.x * scl, hotPos.y * scl, hotPos.z * scl);
	// const hotPosY = deltaY * posRatio * scl || hotPos.y * scl;
	// hotMesh.position.set(hotPos.x * scl, hotPos.z * scl, hotPosY);
	hotMesh.rotation.x = Math.PI/2;
	return hotMesh;
}