import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  @import url('${(props) => props.theme.font.fontSource}');

  *{
    margin:0;
    padding:0;
    outline:0;
    box-sizing:border-box;
  }

  html, body, #root{
    height:100%;
  }

  body{
    background:#fff;
    -webkit-font-smoothing : antialiased !important;
  }

  h1 {
    font-size: ${(props) => props.theme.font.sizes.h1};
    color: ${(props) => props.theme.colors.black};
  }

  h2{
    font-size: ${(props) => props.theme.font.sizes.h2};
    color: ${(props) => props.theme.colors.black};
  }

  h3 {
    font-size: ${(props) => props.theme.font.sizes.h3};
    color: ${(props) => props.theme.colors.black};
  }

  p, span {
    font-size: ${(props) => props.theme.font.sizes.default};
  }

  body, input, button{
    font-size: 14px;
    font-family: ${(props) => props.theme.font.fontFamily};
  }

`
