import React from 'react'
import { Switch } from 'react-router-dom'

import Route from './Route'

import MainComponent from '../components/Main';

const Routes = () => {
  return (
    <Switch>
      <Route path='/' exact component={MainComponent} />
    </Switch>
  )
}

export default Routes
