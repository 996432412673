const theme = {
  font: {
    fontSource:
      'https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap',
    fontFamily: 'Lato, sans-serif',
    sizes: {
      h1: '30px',
      h2: '24px',
      h3: '26px',
      h4: '19px',
      default: '14px',
    },
  },
  colors: {
    green: {
      primary: '#359616',
      light: '#AED5A1',
      darken: '#AED5A1',
    },
    blue: {
      primary: '#17479E',
      light: '#A2B5D8',
      darken: '#0D2A5F',
    },
    white: '#FFFFFF',
    grey: {
      primary: '#DCDCDC',
      darken: '#9E9E9E',
      light: '#EDEDED',
    },
    black: '#191919',
  },
}

export default theme
