import React from 'react';
import jQuery from 'jquery';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import {Tween, autoPlay, Easing} from "es6-tween";

import LoadingComponent from './Loading';
import SettingComponent from './Setting';
import HotModalComponent from './HotModal';
import '../assets/css/index.css';

import {imgArr, modelInfo, imgInfo, colorArr, GetHotMesh} from "./common";

autoPlay(true);
export default class MainComponent extends React.Component {
	constructor(props) {
		super(props);
		this.cWidth = jQuery(window).width(); this.mouseX = 0;
		this.cHeight = jQuery(window).height(); this.mouseY = 0;
		this.raycaster = new THREE.Raycaster();
		this.mouse = new THREE.Vector2(1, 1);
		this.hotMeshArr = []; this.hoverHotId = null;
		this.animate = this.animate.bind(this);
		this.loadCount = 0;
		const device =  ( window.innerHeight > window.innerWidth || window.innerWidth < 1280 ) ? 'mobile':'web'; //  || window.innerWidth < 1360
		this.state = { loading:'loading', disable:false, selModel:modelInfo[8].id, split:false, hotInfo:null, hotClass:'', colorInfo:{Top:'', Base:''}, device, other2530:false, other4045:false};
	}

	componentDidMount() {
		this.initScene();
		this.animate();
		this.setCanvasSize();
		this.loadMapArr();
		window.addEventListener('resize', this.setCanvasSize);
		window.addEventListener('mousemove', this.onMouseMove);
		window.addEventListener("click", this.onclick, true);
		window.addEventListener('touchend', this.onclick, false);
	}

	onMouseMove = (e) => {
		const intersect = this.getMouseObj(e, this.hotMeshArr );
		const hoverHotId = intersect?intersect.object.hotId:null;
		if (hoverHotId !== this.hoverHotId) {
			this.hoverHotId = hoverHotId;
			if (hoverHotId) jQuery("#container").addClass('hotspot-hover');
			else jQuery("#container").removeClass('hotspot-hover');
			this.hotMeshArr.forEach(hotMesh => {
				const targetScl = hotMesh.hotId === this.hoverHotId ? 1.5 : 1;
				this.setTween(hotMesh, "scale", hotMesh.oriScl * targetScl, 500);
			});
		}
	}

	getMouseObj = (e, arr) => {
		this.mouse.x = ( e.clientX / this.cWidth ) * 2 - 1;
		this.mouse.y = - ( e.clientY / this.cHeight ) * 2 + 1;
		this.raycaster.setFromCamera( this.mouse, this.camera );
		return this.raycaster.intersectObjects( arr )[0];
	}

	closeHotModal = () => {
		this.setState({hotClass:''}, () => {
			setTimeout(() => { this.setState({hotInfo:null}); }, 500);
		})
	}

	setColor = (posKey, selColor) => {
		var {selModel, colorInfo} = this.state, meshIdx = posKey === 'Top'?0:1;
		this.totalGroup.children.forEach(group => {
			if (group.modelId !== selModel) return;
			group.children.forEach(child => {
				if (child.name === posKey) child.material.color.setHex(colorArr[selColor.num].val);
			});
			if (selModel === 'model_2530') {
				this.setState({other2530:colorArr[selColor.num].val === group.oriCol?false:true});
			} else if (selModel === 'model_4045') {
				this.setState({other4045:colorArr[selColor.num].val === group.oriCol?false:true});
			}
		});
		colorInfo[posKey].forEach(colorItem => {
			colorItem.set = colorItem.num === selColor.num ? true : false;
		});
		this.setState({colorInfo});
	}

	onclick = (e) => {
		if (e.changedTouches && e.changedTouches[0]) {
			const ePos = e.changedTouches[0];
			if (!ePos || !ePos.clientX || !ePos.clientY) return;
			const intersect = this.getMouseObj(ePos, this.hotMeshArr );
			this.hoverHotId = intersect?intersect.object.hotId:null;
		}
		if (!this.hoverHotId) return;
		const hotInfo = this.hotMeshArr.find(hotMesh => hotMesh.hotId === this.hoverHotId).hotInfo;
		if (this.state.split) this.setSplit();
		this.setState({hotInfo}, () => {
			setTimeout(() => { this.setState({hotClass:'active'}); }, 100);
		})
	}

	setTween = (obj, attr, info, easeTime) => {
		var tweenData = {};
		const easeType = Easing.Cubic.InOut;
		if (attr === "opacity") tweenData = {'opacity':info };
		else if (attr === "position") tweenData = {'position.y':info.y };
		else if (attr === "color") tweenData = {'color':info };
		else if (attr === "scale") tweenData = {'scale.x':info, 'scale.y':info, 'scale.z':info };
		new Tween(obj).to( tweenData , easeTime ).easing(easeType).start();
	}

	setSplit = () => {
		this.setState({split:!this.state.split}, ()=> {
			const targetPos = this.state.split ? 1:0;
			const selModel = this.totalGroup.children.find(group=>group.modelId === this.state.selModel);
			selModel.children.forEach(child => {
				this.setTween(child, 'position', {y:child.oriPosY + selModel.splitDis * child.splitPos * targetPos}, 500);
			});
			if (targetPos && this.state.hotClass!=='') this.closeHotModal();
		})
	}

	setModel = (item) => {
		this.hotMeshArr = [];
		this.totalGroup.children.forEach(group => {
			if (group.modelId === item.id) {
				group.visible = true;
				group.children.forEach(child => {
					child.children.forEach(hotMesh => { this.hotMeshArr.push(hotMesh); });
				});
				this.setState({colorInfo:group.colorInfo});
			}
			else group.visible = false;
		});
		this.setState({selModel:item.id});
		this.closeHotModal();
	}

	loadMapArr = () => {
		var mapArr = [], loadedCount = 0;
		imgArr.forEach((imgUrl, idx) => {
			new THREE.TextureLoader().load(imgUrl, ( texture ) => {
				// texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
				mapArr[idx] = texture;
				loadedCount++;
				if (loadedCount === imgArr.length) {
					this.loadModel(mapArr);
				}
			}, undefined, undefined);
		});
		
	}

	loadModel = (mapArr) => {
		modelInfo.forEach((item, idx) => {
			new FBXLoader().load(item.file, (object)=>{
				const vSize = new THREE.Box3().setFromObject(object).getSize(new THREE.Vector3());
				object.sclWeb = 1.3/vSize.z; object.sclMobile = 0.8/vSize.z;
				object.children.forEach((child, idx) => {
					item.hotInfo.forEach(hotItem => {
						if (hotItem.name === child.name) {
							if (!hotItem.disable) {
								const hotMesh = GetHotMesh(hotItem.type, hotItem.deltaY);
								hotMesh.hotId = item.id+"_"+hotItem.name;
								hotMesh.hotInfo = hotItem;
								child.add(hotMesh);
							}
							child.splitPos = hotItem.splitPos; child.oriPosY = child.position.y;
							object.oriCol = hotItem.col || 0xFFFFFF;
							const map = hotItem.imgNum !== undefined?mapArr[hotItem.imgNum]:null;
							child.material = new THREE.MeshStandardMaterial({color:object.oriCol, roughness:1, map, side:2, roughness:1, metalness:0});
							if (hotItem.type === 'Top') child.castShadow = true;
							else if (hotItem.type === 'Bottom') child.receiveShadow = true;
							else {child.castShadow = true; child.receiveShadow = true;}
						}
					});
				});
				object.modelId = item.id;
				object.colorInfo = item.colorInfo;
				this.totalGroup.add(object);
				if (this.state.selModel === item.id) this.setModel(item);
				else object.visible = false;
				this.loadCount++;
				if (this.loadCount === modelInfo.length) {
					this.setState({loading:'waiting'}, ()=> {this.setOBjectScale();});
				}
			});
		});
	}

	setOBjectScale = () => {
		const device = this.cWidth > this.cHeight ? 'web' : 'mobile';
		const posRatio = device === 'web' ? 1 : 0.533;
		const matPosZ = -2.26 * posRatio, hotPosYBottom = 0.1 * posRatio, hotPosYMiddle = 0.6 * posRatio, hotPosYTop=0.9 * posRatio, hotPosX = 2.2;
		const hotTopPos = {x:hotPosX, y:hotPosYTop, z:matPosZ}, hotBottomPos = {x:-hotPosX, y:hotPosYBottom, z:matPosZ};

		this.totalGroup.children.forEach(object => {
			const scl = device === 'web'?object.sclWeb:object.sclMobile;
			object.scale.set(scl, scl, scl);
			object.splitDis = 0.2 / scl;
			object.children.forEach(child => {
				const hotMesh = child.children[0];
				if (!hotMesh || !hotMesh.hotId) return;
					const hotScale = 11/scl * 0.0254 / child.scale.x;
					hotMesh.scale.set(hotScale, hotScale, hotScale);
					hotMesh.oriScl = hotScale;
					var hotPos = {x:0, y:hotPosYMiddle, z:matPosZ};
					if 		(hotMesh.hotType === 'Top') hotPos = hotTopPos;
					else if (hotMesh.hotType === 'Bottom') hotPos = hotBottomPos;
					const hotPosY = hotMesh.deltaY * posRatio * hotScale || hotPos.y * hotScale;
					hotMesh.position.set(hotPos.x * hotScale, hotPos.z * hotScale, hotPosY);
			});
		});
	}

	initScene = () => {
		this.renderer = new THREE.WebGLRenderer({antialias:true, alpha:true});
		this.renderer.setPixelRatio( window.devicePixelRatio );
		this.renderer.setSize(this.cWidth, this.cHeight);
		if (!document.getElementById("container")) return false;
		document.getElementById("container").appendChild(this.renderer.domElement);
		this.renderer.setClearColor(0x000000, 0);
		this.renderer.shadowMap.enabled = true;
		this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;

		this.camera = new THREE.PerspectiveCamera(60, this.cWidth / this.cHeight, 0.01, 100000);
		this.camera.position.set(-1, 1, 3.2);
		this.scene = new THREE.Scene();
		this.totalGroup = new THREE.Group(); this.scene.add(this.totalGroup);

		this.controls = new OrbitControls(this.camera, this.renderer.domElement);
		this.controls.enablePan = false;
		this.controls.minDistance = 2.4; this.controls.maxDistance = 6;

		const ambientLight = new THREE.AmbientLight( 0xFFFFFF, 1 ); this.scene.add( ambientLight );
		const mainLight = new THREE.DirectionalLight( 0xFFFFFF, 0.5 ); this.scene.add( mainLight );
		mainLight.position.set(-30, 50, 30); mainLight.castShadow = true; mainLight.shadow.darkness = 0.5;
		mainLight.shadow.mapSize.width = mainLight.shadow.mapSize.height = 2048;
	}

	animate=()=>{
		if (!this.camera || !this.scene) return;
		requestAnimationFrame(this.animate);
		this.renderer.render(this.scene, this.camera);
	}

	setCanvasSize = () => {
		this.cWidth = jQuery(window).width();
		this.cHeight = jQuery(window).height();
		const device = this.cWidth > this.cHeight && this.cWidth > 1280 ? 'web' : 'mobile';
		this.setOBjectScale();
		if (device !== this.state.device) {
			this.setState({device});
		}
		if (this.renderer && this.camera) {
			this.renderer.setSize(this.cWidth, this.cHeight);
			this.camera.aspect = this.cWidth/this.cHeight;
			this.camera.updateProjectionMatrix();
		}
	}

	render() {
		const {loading, selModel, split, hotInfo, hotClass, colorInfo, device, other2530, other4045} = this.state;
		return (
			<div className='page-wrapper'>
				<div className='page-back page-top'></div>
				<div className='page-back page-bottom'></div>
				<div className='page-back page-middle'>
					{device === 'web' && '360º VIEW' }
				</div> 
				<img className='logo' src={imgInfo.logo}></img>
				{hotInfo !== null &&
					<HotModalComponent
						hotClass={hotClass}
						hotInfo={hotInfo}
						selModel={selModel}
						other2530={other2530}
						other4045={other4045}
						closeHotModal={this.closeHotModal}
					></HotModalComponent>
				}
				<div className={`container ${hotInfo===null?'':'hotModal'} ${device}`} id='container'></div>
				{device === 'web' && split === false &&
					<img className='split-button' src={imgInfo.split} onClick={this.setSplit}></img>
				}
				{device === 'mobile' && split === false && // hotInfo === null &&
					<div className='split-button mobile'><img src={imgInfo.split} onClick={this.setSplit}></img></div>
				}
				{split === true && <img className='split-close' src={imgInfo.close} onClick={this.setSplit}></img>}
				<SettingComponent
					selModel={selModel}
					colorInfo={colorInfo}
					split={split}
					setModel={this.setModel}
					setColor={this.setColor}
				></SettingComponent>
				<LoadingComponent
					loading={loading}
					clickWaitPan={()=>this.setState({loading:''})}
				></LoadingComponent>
			</div>
		);
	}
}
