import React from 'react';
import '../assets/css/index.css';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

import {modelInfo, imgInfo, colorArr} from "./common";
export default class SettingComponent extends React.Component {
	constructor(props) {
		super(props);
		const device =  ( window.innerHeight > window.innerWidth || window.innerWidth < 1280 ) ? 'mobile':'web';
		this.state = { selModel:props.selModel, colorInfo:props.colorInfo, split:props.split, device, track:false};
		this.primaryRef   = React.createRef();
		this.secondaryRef = React.createRef();
	}

	componentDidMount() {
		window.addEventListener('resize', this.onReSize);
		this.setState({track:true}, ()=> {
			this.primaryRef.current.sync( this.secondaryRef.current.splide );
		});
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.state.selModel !== nextProps.selModel) {
			this.setState({selModel:nextProps.selModel});
		}
		if (this.state.colorInfo !== nextProps.colorInfo) {
			this.setState({colorInfo:nextProps.colorInfo});
		}
		if (this.state.split !== nextProps.split) {
			this.setState({split:nextProps.split}, () => {
				this.setSplideStyle();
			});
		}
	}

	setSplideStyle = () => {
		if (!this.primaryRef.current || !this.secondaryRef.current) return;
		this.primaryRef.current.sync( this.secondaryRef.current.splide );
	}

	onReSize = () => {
		const device = window.innerWidth > window.innerHeight && window.innerWidth > 1280 ? 'web' : 'mobile';
		this.setState({device, track:false}, ()=> 
			this.setState({track:true}, () => {
				this.setSplideStyle()
			})
		);
	}

	render() {
		const {selModel, colorInfo, split, device, track} = this.state;
		const splitLabels = colorInfo?Object.keys(colorInfo):[];
		const selModelImg = modelInfo.find(item => {return item.id===selModel}).img;
		const setItemWidth = device === 'web' ? 200 : 160, setItemHeight = device === 'web' ? 120 : 90;
		const startIdx = modelInfo.findIndex(item => item.id === selModel);
		return (
			<div className='setting'>
				{split === false && track === true &&
					<>
						<Splide options={ {type      : 'loop',
									rewind : false,
									width  : 0,
									height:0,
									pagination: false
							} } ref={ this.primaryRef }>
						</Splide>
						<Splide
							className={`splide-content ${split === true ? 'down':''}`}
							options={ {
								rewind : false,
								fixedWidth:setItemWidth,
								fixedHeight: setItemHeight,
								perMove: 3,
								width  : window.innerWidth,
								start:startIdx,
								isNavigation: true,
								gap    : '1rem',
								cover : true,
								trimSpace : false,
								pagination  : false,
								focus : 'center',
							} }
							onMoved={ ( splide, newIndex ) => { this.props.setModel(modelInfo[newIndex]) } }
							ref={ this.secondaryRef }
						>
							{ modelInfo.map( (item, idx) => (
								<SplideSlide key={ idx }>
									<img src={ item.img } alt={ '' } />
									<div>{item.label}</div>
								</SplideSlide>
							) ) }
						</Splide>
					</>
				}
				{split === true && splitLabels.map((posKey, idx) =>
					<div className={`set-item set-color`} key={idx}>
						<div className={`set-color-wrapper ${colorInfo[posKey].length===0?'empty':''}  split-count-${splitLabels.length}`}>
							<div className='color-content'>
								{colorInfo[posKey].map((colorItem, colorIdx) =>
									<div key={colorIdx} className={`set-color-item `} onClick={()=>this.props.setColor(posKey, colorItem)}>
										<div className={`set-color-inner ${(colorItem.set===true)?'active':''}`}
											style={{ backgroundColor: `${colorArr[colorItem.num].str}` }}>
										</div>
									</div>
								)}
							</div>
							<div className='image'><img src={selModelImg}></img></div>
							<div className='label'>{posKey}</div>
						</div>
					</div>
				)}
			</div>
		);
	}
}
